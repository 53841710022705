body {
  font-family: Figtree;
}

.heading {
  font-family: Figtree;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  font-size: 30px;
  padding: 10px 0px;
}

.content {
  /* font-family: Figtree; */
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  /* color: #505052; */
  font-size: 20px;
  padding: 15px 0px;
}

@media only screen and (max-width: 770px) {
  .cvstylesoutside {
    padding: 25px 15px;
  }
  .cvstylesinside {
    margin-left: auto;
    margin-right: auto;
  }
  .sampleNoStyles {
    margin-left: auto;
    margin-right: auto;
  }
  .heading {
    line-height: 40px;
    font-size: 25px;
    padding: 10px 0px;
  }

  .content {
    line-height: 28px;
    font-size: 18px;
  }
}
