.upperGrid {
  border: 1px solid rgb(78, 78, 78);
  padding: 2%;
  border-radius: 10px;
}

.submitButton {
  background: rgb(0, 128, 0);
}

.saveButton {
  background: rgb(165, 58, 189);
}

.nextButton {
  background: rgb(68, 31, 200);
}
