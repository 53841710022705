.heading {
    margin: 0;
    font-family: Figtree;
    font-weight: 900;
    line-height: 48px;
    text-align: left;
    font-size: 38px;

  }
   
  .content {
    font-family: Figtree;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #505052;
    font-size: 20px;
    
  }