body {
  font-family: Figtree;
}

.heading {
  margin: 0;
  font-family: Figtree;
  font-weight: 900;
  line-height: 48px;
  text-align: left;
  font-size: 30px;
}

.content {
  font-family: Figtree;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #505052;
  font-size: 20px;
}
.Numbersetingbox {
  width: 180;
}
.particularstyles {
  margin-left: auto;
  margin-right: auto;
}
.headingsub {
  margin: 0;
  font-family: Figtree;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  font-size: 23px;
}
.addbutton {
  /* width: 200px; */
  /* height: 50px; */
}
/* .submitbutton {
  width: 150px;
  height: 50px;
} */
@media only screen and (max-width: 770px) {
  .heading {
    line-height: 40px;
    font-size: 25px;
    padding: 10px 0px;
  }
  .Numbersetingbox {
    width: 300;
  }
  .content {
    line-height: 28px;
    font-size: 18px;
  }
  .headingsub {
    line-height: 34px;
    text-align: left;
    font-size: 20px;
  }
  /* .addbutton{
        width: 165px;
        height: 45px;
     } */
  /* .submitbutton {
    width: 130px;
    height: 45px;
  } */
}
