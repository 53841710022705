@import url("https://fonts.googleapis.com/css?family=Roboto");

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  box-sizing: border-box;
  overflow-x: hidden;
  scrollbar-width: none;
}
p {
  margin: 0;
}

/* ::-webkit-scrollbar {
    display: none;
  } */

body::-webkit-scrollbar {
  display: none;
}

h3,
h2,
h4 {
  margin: 8px;
}

th {
  font-size: 16px;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: #000;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

.red-text {
  color: red;
}

.green-text {
  color: green;
}

.overflowContain {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* For general overflow hidden (both horizontal and vertical) */
.overflowHidden {
  overflow: hidden;
}

/* General styling for the scrollbar */
.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #e0e0e0; /* Scrollbar thumb and track color for Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e0e0e0; /* Track color */
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 4px; /* Roundness of the scrollbar thumb */
  border: 2px solid #e0e0e0; /* Padding around scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb color when hovered */
}
