body{
    font-family: Figtree;
}
.heading {
    margin: 0;
    font-family: Figtree;
    font-weight: 900;
    line-height: 48px;
    text-align: left;
    font-size: 30px;

  }

  .submitbutton{
    width: 150px;
    height: 45px;
   }

   .content {
    font-family: Figtree;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #505052;
    font-size: 20px;
    padding: 15px 0px; 
  }
 
  
   @media only screen and (max-width: 770px) {
    .heading {  
        line-height: 40px;
        font-size: 25px;
        padding: 10px 0px;
      }
       
     
      .content {
        line-height: 28px;
        font-size: 18px;
      }
   .submitbutton{
     width: 135px;
     height: 45px;
   }

}