@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

.welcome-text {
  margin: 0;
  /* // font-family: Poppins; */
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: #1a1a1a;
}

.login-text {
  margin: 0;
  /* // font-family: Poppins; */
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.30000001192092896px;
  color: #333333;
}

.login-btn {
  padding: 10px 24px;
  border-radius: 6px;
  background: #f01013;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.3px;
}
