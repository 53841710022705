.view-btn {
  background: rgb(248, 161, 175);
  text-transform: none;
}

.add-btn {
  background: rgb(222, 64, 101);
  text-transform: none;
}

.edit-delete-btn {
  background: rgba(226, 229, 232, 0);
  margin-right: 4%;
}
